@import "~@angular/material/prebuilt-themes/pink-bluegrey.css";
html,
body {
    height: 100%;
    margin: 0px;
    font-family: 'Roboto', sans-serif;
}

@page {
    margin-top: 0;
    margin-bottom: 0;
}